import React, { Component } from 'react';
import { Row } from 'react-bootstrap';

class Contact extends Component {
    render() {
        return (
            <Row className="contactContainer" id="contact">
                <div className="contactInnerContainer">
                    <h1 className="contactTitle">KONTAKT</h1>
                    <div className="specificContactContainer">
                        <div className="contactBlock">SPLOŠNE INFORMACIJE</div>
                        <div className="contactBlockEmail"><strong>info@mdns-maribor.si</strong></div>
                    </div>
                    {/* <div className="specificContactContainer">
                        <div className="contactBlock">PREDSEDNIK MDNS MARIBOR</div>
                        <div className="contactBlockName"><strong>SILVO BOROSAK</strong></div>
                        <div className="contactBlockName">silvo@gmail.com</div>
                    </div>
                    <div className="specificContactContainer">
                        <div className="contactBlock">PODPRESEDNIK MDNS MARIBOR</div>
                        <div className="contactBlockName"><strong>ZORAN NOVARLIC</strong></div>
                        <div className="contactBlockName">zoki@gmail.com</div>
                    </div> */}
                </div>
            </Row>
        )
    }
}

export default Contact;