import React, { Component } from 'react';
import Images from './BillboardImages.js';
import { Row } from 'react-bootstrap';

class Billboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            imageLoaded: false,
          };
    }

    handleImageLoad = () => {
        this.setState({ imageLoaded: true });
      };

    render(){
        const { imageLoaded } = this.state;



        var randomImg = Images[Math.floor(Math.random()*Images.length)];
        //style={{backgroundImage: `url(${ROOT_URL + '/' + bgImg})`
        var tmpBgd = randomImg;

        return (
            <Row className="billboardContainer">
                {/* <img className="billboard" src={tmpBgd} alt=""></img>
                <img className="billboardLogo" src='aboutLogo.png' alt=""></img> */}
                <img 
                    alt="" 
                    src='aboutLogo.png' 
                    loading="lazy" 
                    className={imageLoaded ? 'loaded' : ''}
                    onLoad={this.handleImageLoad}
                />
            </Row>
        );
    }
}

export default Billboard;