import React, { Component } from 'react';
import {Container , Row, Col} from 'react-bootstrap'
import './App.css';

class App extends Component {
  
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="main">
        <Container fluid>
        {
          this.props.children
        }
        </Container>

      </div>
    );
  }

}

export default App;
