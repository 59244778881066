import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Billboard from './components/Billboard/Billboard';
import NavBar from './components/NavBar/NavBar.js';
import News from './components/News/News.js';
import About from './components/About/About.js';
import Contact from './components/Contact/Contact.js';
import Footer from './components/Footer/Footer.js';
import Sponsors from './components/Sponsors/Sponsors.js'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App>
      <Billboard name="Billboard"></Billboard>
      <NavBar name="NavBar"></NavBar>
      <News name="News"></News>
      <About name="About"></About>
      <Sponsors name="Sponsors"></Sponsors>
      <Contact name="Contact"></Contact>
      <Footer name="Footer"></Footer>
    </App>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
