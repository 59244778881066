import React, {Component} from 'react';
import { Row } from 'react-bootstrap';

class About extends Component {
    render(){
        return (
            <Row className="aboutContainer" id="about">
                <h1>
                    O DRUŠTVU
                </h1>
                <p className="aboutArticle">
                MDNS Maribor je bilo ustanovljeno leta 1947 in prav letos praznuje 77 let delovanja.
Število članov je že zdavnaj preseglo številko 100, kar priča o bogati zgodovini in pomembni vlogi, ki jo društvo igra v nogometnem svetu.

V preteklosti je MDNS Maribor igralo ključno vlogo v okviru bivše skupne države, kjer so nekateri člani zasedali vodilne položaje. Edvard Šoštarič, dolgoletni sodnik 1. Lige bivše države in mednarodni sodnik, je s svojo prisotnostjo na Olimpijskih igrah v Los Angelesu leta 1984, pustil neizbrisen pečat.

Po osamosvojitvi Slovenije je MDNS Maribor nadaljevalo s tradicijo odličnosti, izpostaviti velja prve FIFA sodnike kot so Darko Jamšek, Josip Jakopiček, Zoran Novarlič in Silvo Borošak, ter Draga Kosa, ki se je na FIFA listo uvrstil leta 1998.

Vodstvo MDNS Maribor sestavlja Izvršni odbor z 7 člani in Nadzorni odbor, ki skrbno nadzira njihovo delo. Osrednji cilj društva ostaja izobraževanje in razvoj sodniških kadrov, od pripravnikov do sodnikov na najvišjih listah, pod vodstvom Slavka Vinčiča ter Silva Borošaka.

Zoran Novarlič, ki zaseda ključno funkcijo v Komiteju ZNSS za izobraževanje pomočnikov sodnikov, je prav tako pomemben člen v naši ekipi in igra ključno vlogo pri nadaljnjem razvoju in izobraževanju naših sodnikov.

V prihodnje bo MDNS Maribor še naprej sledilo smernicam ZNSS, s ciljem ohranjanja in nadgradnje uspeha na vseh ravneh sojenja.

<br></br>
<br></br>
</p>
<div class="aboutEntities">
<h3>ORGANI DRUŠTVA</h3>

<ul class="list-unstyled text-center">
    <li class="aboutEntity">UPRAVNI ODBOR</li>
            <ul class="list-group list-group-flush text-center">
                <li class="list-group-item">Slavko Vinčić (predsednik)</li>
                <li class="list-group-item">Silvo Borošak (podpredsednik)</li>
                <li class="list-group-item">Alen Borošak (član)</li>
                <li class="list-group-item">Drago Koletnik (član)</li>
                <li class="list-group-item">Admir Zahović (član)</li>
                <li class="list-group-item">Armando Šorli (član)</li>
                <li class="list-group-item">Bojan Tkalčič (član)</li>
            </ul>
    <li class="aboutEntity">NADZORNI ODBOR</li>
            <ul class="list-group list-group-flush text-center">
                <li class="list-group-item">Tadej Mežnar (predsednik)</li>
                <li class="list-group-item">Aleksandar Matković (član)</li>
                <li class="list-group-item">Damjan Novarlić (član)</li>
            </ul>
    <li class="aboutEntity">ARBITRAŽNA KOMISIJA</li>
            <ul class="list-group list-group-flush text-center">
                <li class="list-group-item">Tomaž Klančnik (predsednik)</li>
                <li class="list-group-item">Uroš Rađević (član)</li>
                <li class="list-group-item">Dejan Djukić (član)</li>
            </ul>
</ul>
</div>


                
                <img className="aboutImage" src='aboutLogo.png' loading="lazy"></img>
            </Row>
        )
    }
}

export default About;