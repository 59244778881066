import React, { Component } from 'react';
import { Row } from 'react-bootstrap';

class Footer extends Component {
    render() {
        return (
            <Row className="footerContainer">
                <div className="footerInnerContainer">
                    <div className="footerImgContainer">
                    <img src={process.env.PUBLIC_URL + '/603faf51da332d472d27b021_2.png'} width="62"></img>
                    </div>
                    <div className="footerText">@MDNS MARIBOR. Vse pravice pridrzane.</div>
                </div>
            </Row>
        )
    }
}

export default Footer;