import React, { Component } from 'react';
import { Row } from 'react-bootstrap';

class News extends Component {
    render(){
        return (
            <Row className="newsContainer" id="news">
                <h1>
                    NOVICE
                </h1>
                <div className="mainNews">
                    <div className="newsArticle1">
                    <h3>Priporočila za uporabo komunikacijskega sistema</h3>
                            <p>
                            Tomaž Jezeršek, sodnik 3. slovenske nogometne lige, s priporočili o uporabi komunikacijskega sistema AXIWI (klik na spodnjo sliko)
                            </p>
                            <a href="axiwi-clanek.pdf" target="_blank">
                                <img className="newsImage rounded img-thumbnail mx-auto" src='komunikacijaNavodila.png' loading="lazy"></img>
                            </a>
                    </div>
                    <div className="newsArticle2">
                    <h3>AXIWI na 2.SNL</h3>
                            <p>
                            Mariborski sodniki na tekmi 2. SNL z novim komunikacijskim sistemom AXIWI.
                            </p>
                            <a href="snl2.jpg" target="_blank">
                                <img className="newsImage rounded img-thumbnail mx-auto" src='snl2.jpg' loading="lazy"></img>
                            </a>
                    </div>
                    <div className="newsArticle3">
                        <h3>Komunikacijski sistemi AXIWI</h3>
                            <p>
                            MDNS Maribor je sklenilo sodelovanje z nizozemskim ponudnikom komunikacijskih sistemov za sodnike, AXIWI. Nove komunikacijski komunikacijski sistemi AXIWI AT-350 bodo sodnikom v pomoč pri deljenju pravice na slovenskih igriščih.
                            </p>
                        <a href="https://www.axiwi.com/ " target="_blank">
                            <img className="newsImage rounded" src='axiwiLogo.png' loading="lazy"></img>
                        </a>
                    </div>                    
                </div>
            </Row>
        )
    }
}

export default News;