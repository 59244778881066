import React, { Component } from 'react';
import { Row } from 'react-bootstrap';

class Sponsors extends Component {
    render() {
        return (
            <Row className="sponsorsContainer" id="sponsors">
                <h1>PARTNERJI</h1>
                <div className="sponsorInnerContainer">                    
                    <img className="sponsorImage" alt="sava" src='savaLogo.jpg' loading="lazy"></img>
                    <img className="sponsorImage" alt="lm" src='axiwiLogo.png' loading="lazy"></img>
                </div>
            </Row>
        )
    }
}

export default Sponsors;