import React, {Component} from 'react';
import { Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'

class NavBar extends Component {
    render(){
        return (
                <Row className="navbarContainer sticky-top">
                    {/* <div class="hambMenu" onclick="toggleMobileMenu()">
                        <div class="hambMenuBar"></div>
                        <div class="hambMenuBar"></div>
                        <div class="hambMenuBar"></div>
                    </div> */}
                    {/* <a class="mobile-menu-icon">
                        <div class="hambMenuLine"></div>
                        <div class="hambMenuLine"></div>
                        <div class="hambMenuLine"></div> */}
{/* 
                        <ul id="menu">
                                <a href="#root"> <li>DOMOV</li></a>
                                <a href="#news"> <li>NOVICE</li></a>
                                <a href="#about"> <li>O DRUŠTVU</li></a>
                                <a href="#sponsors"> <li>PARTNERJI</li></a>
                                <a href="#contact"> <li>KONTAKT</li></a>
                        </ul> */}
                    {/* </a>	 */}
                    <nav role="navigation" className="navbarList">
                        <a href="#root" className="navbarLink">DOMOV</a>
                        <a href="#news" className="navbarLink">NOVICE</a>
                        <a href="#about" className="navbarLink">O DRUŠTVU</a>
                        <a href="#sponsors" className="navbarLink">PARTNERJI</a>
                        <a href="#contact" className="navbarLink">KONTAKT</a>
                    </nav>
                </Row>
        )
    }
}

export default NavBar;